@media (max-width: 700px) {
  .FrontPage-background {
    position: absolute;
    display: block;
    left: 0px;
    width: auto;
  }
}

@media only screen and (min-width: 1px) and (max-width: 700px) {
  .FrontPage-background {
    display: none;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .video-container {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .video-container {
    margin-bottom: 60px;
  }
  .video-row {
    display: flex;
  }
  .video-one {
    display: block;
    margin-left: 115px;
    justify-content: center;
    align-items: center;
    iframe {
      width: 760px;
      height: 415px ;
    }
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .video-frame {
    width: 320px;
    height: 200px;
  }
}

@media (min-width: 1000px) {
  .video-frame {
    width: 560px;
    height: 315px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .form-control {
    background-color: #000000 !important;
    width: 310px;
    border: 2px solid #4e664e;
  }
}

@media only screen and (min-width: 1000px) {
  .form-control {
    background-color: #000000 !important;
    width: 500px;
    border: 2px solid #4e664e;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .article-page {
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.8rem;
  }
}

@media (min-width: 1000px) {
  .article-page {
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 17em;
    margin-right: 17em;
  }
}

@media (min-width: 1000px) {
  .FrontPage-background {
    z-index: -1;
    position: absolute;
    display: block;
    left: 0px;
    width: 100%;
    height: 1000px;
    object-fit: cover;
  }
}

.color-nav {
  color: #c7c5c5;
  padding: 5;
  text-decoration: none !important;
  &:focus, &:hover, &:active {
    text-decoration: none;
    color: #4e664e;
}
}

h2 {
  color: #4e664e;
}

.footer-line {
  border: 0.25px;
  min-height: 0.25em;
  line-height: 0.25;
}

.some-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

$block: ".quantity-input";
$border: 0 solid #dbdbdb;
$border-radius: 6px;
$color--primary: #eaf2ea;
$modifier--text-color: #888;

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.16), 0 1.5px 3px rgba(0, 0, 0, 0.23);
  border-radius: $border-radius;

  &:focus {
    background: red;
  }
}

#{$block}__modifier,
#{$block}__screen {
  user-select: none;
  outline: none;
}

#{$block}__modifier {
  padding: 0.7rem;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;

  background: $color--primary;
  color: $modifier--text-color;
  border: $border;
  text-align: center;
  text-shadow: 0 1px 0 rgba(#fff, 0.6);

  cursor: pointer;

  &:hover {
    background: darken($color--primary, 10%);
    color: darken($modifier--text-color, 20%);
  }

  &--left {
    border-radius: $border-radius 0 0 $border-radius;
  }

  &--right {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

#{$block}__screen {
  width: 2.6rem;
  padding: 0.8rem;
  font-size: 1rem;

  border: 0;
  border-top: $border;
  border-bottom: $border;
  text-align: center;
}

.navbar-toggler {
  position: fixed;
  right: -10px;
  display: list-item;
}

a {
  text-decoration: none;
  color: rgb(150, 150, 150);
}

td {
  padding-right: 4px;
  vertical-align: top;
}

.navbar-expand-lg {
  right: -10px;
  top: 50px;
  position: fixed;
  z-index: 2;
}
